.App {
  text-align: center;  
}

.coupon-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.coupon {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  margin: 20px 20px 20px 20px;
}

.coupon-label {
  padding: 0px 10px 0px 0px;
}

.coupon-row {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.toolbar {
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.topMenuLinks {  
  text-decoration: none;
  color:"#FFFFFF"
}

.game {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 20px 20px 20px;
}


.card-actions {
  justify-content: flex-end;
}

.nav-link {
  text-decoration: none;
}

.fixture span {
  font-size: 14px;
}

.fixture {
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.fixture-score span {
  font-size: 14px;
  font-weight: 700;
}

.fixture img {
  margin-right: 5px;
}

.odds-table {
  font-size: 12px;
}

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}